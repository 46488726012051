<template>
    <v-container style="height: 90vh">
        <v-col cols= breakpoints class="mx-auto">
            <v-row class="justify-space-between my-5">
                <v-col cols="4">
                    <h2>Lager Rezepte</h2>
                </v-col>
                <v-col cols="4">
                    <v-tabs
                        v-model="tabs"
                        centered
                    >
                    <v-tab
                        v-for="n in Switcher"
                        :key="n"
                    >
                        {{ n }}
                        </v-tab>
                    </v-tabs>
                </v-col>
                <v-col cols="4">
                    <div>
                        <v-btn color="primary" :href="url + pdfUrl">PDF Anschauen</v-btn>
                        <v-btn color="secondary" class="ml-2" @click="EinkaufslistenExport">Einkaufsliste Exportieren</v-btn>
                    </div>
                </v-col>                
            </v-row>
            <v-tabs-items v-model="tabs">
                        <v-tab-item>
                            <v-card flat>
                            <v-card-text>
                                <v-list class="mx-5">
                            <v-list-item
                                v-for="item in items"
                                :key="item._id"
                                link
                                @click="changeRoute(item._id)"
                                >
                                <v-list-item-content>
                                        <v-list-item-title class="flex flex-row justify-space-between">
                                            {{ item.Titel }}
                                            <v-icon>{{ "mdi-arrow-right" }}</v-icon>
                                        </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                            </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card flat>
                            <v-card-text>
                                <v-list class="mx-5">
                            <v-list-item
                                v-for="item in items"
                                v-show="item.ShowInPDF"
                                :key="item._id"
                                link
                                @click="changeRoute(item._id)"
                                >
                                <v-list-item-content>
                                        <v-list-item-title class="flex flex-row justify-space-between">
                                            {{ item.Titel }}
                                            <v-icon>{{ "mdi-arrow-right" }}</v-icon>
                                        </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                            </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
        </v-col>
        <v-btn
        color="primary"
        elevation="24"
        absolute
        bottom
        fab
        >
        <v-icon @click="createRezept()">mdi-plus</v-icon>
        </v-btn>
    </v-container>
</template>

<script>
import axios from 'axios'
export default {
    data () {
        return {
            Switcher: ["Alle Rezepte", "Kochbuch 2021"],
            tabs: null,
            items: [],
            filtered: [],
            showFiltered: [],
            url: 'https://kjg-api.rezept-zettel.de/api',
            pdfUrl: '/rezepte/Kochbuch_Sommerlager_2021.pdf',
            exportURL: 'rezepte/einkaufsliste'
        }
    },
    computed: {
        /* eslint-disable */ 
      breakpoints () { 
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 12
          case 'sm': return 12
          case 'md': return 6
          case 'lg': return 6
          case 'xl': return 6
        }
      },
    },
    methods: {
        EinkaufslistenExport() {
            axios.get(`${this.url}/${this.exportURL}`)
            .then(response => {
                window.location =response.data
            })
            .catch(error => {
                console.log(error)
            })
        },
        createRezept() {
            this.$router.push('/rezept-erstellen')
        },
        getItems () {
            axios.get('https://kjg-api.rezept-zettel.de/api/rezepte')
            .then(
                response => {
                    this.items = response.data
                    for(let x = 0; x < response.data.length; x++) {
                        if(response.data[x].ShowInPDF) {
                        this.filtered.push(response.data[x])
                    }
                }
            })
        },
        changeRoute(id) {
            this.$router.push(`/rezepte/${id}`)
        }
    },
    created() {
        this.getItems()
    }
}
</script>

<style>

</style>